$black: #212121;

// minimal reset for shadow root
//
*, ::after, ::before {
    box-sizing: border-box;
}

p {
    margin: 0 0 1rem;
}

// pre-header component
//
.ph {
    position: relative;
    z-index: 2000;
    color: #fff;
    background-color: $black;

    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;

    p {
        margin-bottom: 0.625rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3;

        &.title {
            margin-bottom: 0.5rem;
            font-family: Feijoa, Georgia, 'Times New Roman', serif;
            font-weight: 300;
            line-height: 1.2;
        }

        a {
            margin-top: 4px;
            color: #fff;

            &.arrow {
                &::after {
                    display: inline-block;
                    width: 14px;
                    height: 10px;
                    margin-left: 0.5rem;
                    background: url('data:image/svg+xml;charset=utf8,%3Csvg width=\'14\' height=\'10\' viewBox=\'0 0 14 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M9.00065 0.333252L8.06065 1.27325L11.114 4.33325H0.333984V5.66659H11.114L8.05398 8.72659L9.00065 9.66659L13.6673 4.99992L9.00065 0.333252Z\' fill=\'white\'/%3E%3C/svg%3E');
                    content: '';
                }
            }
        }
    }

    .container {
        width: 100%;
        max-width: 1600px;
    }

    .toggle-row {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: #fff;
            cursor: pointer;

            span {
                align-self: baseline;
                font-size: 0.875rem;
                opacity: 0.7;
            }

            i {
                display: inline-block;
                width: 8px;
                height: 6px;
                margin-left: 0.25rem;
                background-image: url('data:image/svg+xml;utf8,%3Csvg width=\'8\' height=\'6\' viewBox=\'0 0 8 6\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M7.06 0.726562L4 3.7799L0.94 0.726562L0 1.66656L4 5.66656L8 1.66656L7.06 0.726562Z\' fill=\'white\'/%3E%3C/svg%3E');
                background-repeat: no-repeat;
                content: '';
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 48px;
        z-index: 1200;
        display: none;
        width: 100%;
        overflow: hidden;
        background-color: $black;
        transition: all 0.5s ease-in-out;

        .container {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @media (min-width: 992px) {
                background-image: none !important;
            }

            ul.brand-slides {
                display: none;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                list-style-type: none;

                @media (min-width: 992px) {
                    display: flex;
                }

                >li {
                    position: relative;
                    z-index: 0;
                    display: grid;
                    flex-shrink: 0;
                    grid-template-areas: 'spacer brand-content';
                    grid-template-columns: 3fr 2fr;
                    width: calc(100%);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    opacity: 0;
                    transition: opacity 800ms cubic-bezier(0.25, 1, 0.5, 1) 0s;

                    &::after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        display: block;
                        background-image: linear-gradient(to bottom, $black 0%, rgba(33, 33, 33, 0) 40%, rgba(33, 33, 33, 0) 60%, $black 100%), linear-gradient(to right, $black 0%, rgba(33, 33, 33, 0) 33%, rgba(33, 33, 33, 0) 66%, $black 100%);
                        content: '';
                    }

                    &.active {
                        z-index: 1;
                        opacity: 1;
                    }

                    .spacer {
                        grid-area: spacer;
                    }

                    .brand-content {
                        z-index: 1;
                        display: flex;
                        flex-direction: column;
                        grid-area: brand-content;
                        align-items: flex-end;
                        margin: 60%  2.5rem 2.5rem 0;

                        @media (min-width: 1200px) {
                            margin-top: 55%;
                        }

                        p {
                            font-weight: 400;
                            text-align: right;

                            &.title {
                                font-size: 3rem;
                            }

                            &.lead {
                                font-size: 1.125rem;
                                opacity: 0.7;
                            }

                            a {
                                display: inline-block;
                                border-radius: 45px;
                                padding: 0.875rem 1.5rem;
                                color: #fff;
                                background-color: rgba(255,255,255,0.4);
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .content-static {
                z-index: 5;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1.5rem;

                @media (min-width: 992px) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 55%;
                    height: 100%;
                    padding: 2.5rem 0 2.5rem 2.5rem;
                }

                @media (min-width: 1200px) {
                    width: 50%;
                }

                #content-main {
                    margin-bottom: 1rem;

                    @media (min-width: 576px) {
                        padding-right: 20%;
                    }

                    @media (min-width: 768px) {
                        padding-right: 30%;
                    }

                    @media (min-width: 992px) {
                        width: 90%;
                        margin-bottom: 4rem;
                    }

                    p {
                        &.title {
                            font-size: 1.75rem;

                            @media (min-width: 992px) {
                                font-size: 2.25rem;
                            }
                        }

                        a {
                            font-size: 1.125rem;
                            text-decoration: underline;
                        }
                    }
                }

                .ph-title--slider-nav {
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 1.25rem;

                    @media (min-width: 992px) {
                        display: none;
                    }
                }

                #slider-nav {
                    display: flex;
                    flex-flow: row;

                    ul {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        row-gap: 0.5rem;
                        width: 100%;
                        margin-bottom: 0;
                        margin-left: 0;
                        padding-left: 0;
                        column-gap: 0.5rem;
                        list-style: none;

                        @media (min-width: 768px) {
                            grid-template-columns: 1fr 1fr 1fr;
                            column-gap: 1rem;
                        }

                        li {
                            margin-bottom: 0.5rem;

                            a {
                                display: block;
                                border-radius: 45px;
                                padding: 0.75rem 0;
                                color: #fff;
                                background-color: rgba(0,0,0,0.7);
                                font-size: 0.875rem;
                                text-align: center;
                                text-decoration: none;
                                cursor: pointer;

                                &::after {
                                    display: inline-block;
                                    width: 9px;
                                    height: 9px;
                                    margin-left: 0.5rem;
                                    background-image: url('data:image/svg+xml;utf8,%3Csvg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.50028 1V7.5C9.50028 7.63261 9.4476 7.75979 9.35383 7.85355C9.26006 7.94732 9.13289 8 9.00028 8C8.86767 8 8.74049 7.94732 8.64672 7.85355C8.55296 7.75979 8.50028 7.63261 8.50028 7.5V2.20687L1.35403 9.35375C1.26021 9.44757 1.13296 9.50028 1.00028 9.50028C0.867596 9.50028 0.740348 9.44757 0.646528 9.35375C0.552708 9.25993 0.5 9.13268 0.5 9C0.5 8.86732 0.552708 8.74007 0.646528 8.64625L7.7934 1.5H2.50028C2.36767 1.5 2.24049 1.44732 2.14672 1.35355C2.05296 1.25979 2.00028 1.13261 2.00028 1C2.00028 0.867392 2.05296 0.740215 2.14672 0.646447C2.24049 0.552679 2.36767 0.5 2.500280.5H9.00028C9.13289 0.5 9.26006 0.552679 9.35383 0.646447C9.4476 0.740215 9.50028 0.867392 9.50028 1Z" fill="white"/%3E%3C/svg%3E ');
                                    background-repeat: no-repeat;
                                    content: '';
                                }

                                @media (min-width: 992px) {
                                    display: inline-block;
                                    border-radius: 0;
                                    padding: 0;
                                    background-color: unset;
                                    font-size: 1rem;
                                    text-align: left;
                                    opacity: 0.7;

                                    &::after {
                                        display: none;
                                    }

                                    &.active {
                                        opacity: 1;

                                        &::before {
                                            display: inline-block;
                                            margin-right: 0.5rem;
                                            content: '—';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .slider-progress {
                    display: none;

                    @media (min-width: 992px) {
                        position: relative;
                        display: block;
                        width: 4px;
                        height: 100%;
                        margin-right: 1.5rem;
                        border-radius: 21px;
                        background-color: rgba(255, 255, 255, 0.5);

                        div {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 0;
                            background-color: #fff;
                            animation-duration: 4s;
                            animation-timing-function: ease-out;
                            animation-iteration-count: infinite;
                            animation-fill-mode: forwards;
                        }
                    }
                }
            }
        }
    }

    &.active {
        .toggle-row {
            i {
                background-image: url('data:image/svg+xml;utf8,%3Csvg width=\'8\' height=\'6\' viewBox=\'0 0 8 6\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0.94 5.27344L4 2.2201L7.06 5.27344L8 4.33344L4 0.333437L8.21774e-08 4.33344L0.94 5.27344Z\' fill=\'white\'/%3E%3C/svg%3E');
            }
        }

        .dropdown {
            display: flex;
            justify-content: center;
            transform-origin: top center;
            animation: dropdown 300ms ease-in-out forwards;
        }
    }
}

@keyframes dropdown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes progress {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}
